import { request } from "@/utils/request"

/**
 * 
 *权限管理接口
 */

//权限列表
export function PermitList(data, pagenum, size) {
    return request({
        url: `api/user/permit/areaList/${pagenum}/${size}`,
        method: 'post',
        data
    })
}


//通过角色id查权限列表
export function RoleIdPermitList(roleId) {
    return request({
        url: `/api/user/permit/getByRoleId/${roleId}`,
        method: 'get'
    })
}

//新增权限
export function addPermit(data) {
    return request({
        url: `/api/user/permit/add`,
        method: 'post',
        data
    })
}

//更新权限
export function updatePermit(data,id) {
    return request({
        url: `/api/user/permit/update/${id}`,
        method: 'put',
        data
    })
}


//删除权限
export function deletePermit(id) {
    return request({
        url: `/api/user/permit/delete/${id}`,
        method: 'delete',
    })
}
