import { request } from '@/utils/request'

/**
 * 资产绑定gps设备
 */

// 获取GPS设备列表
export function getGpsList(assetsId) {
    return request({
        url: `/api/manage/gpsDevice/getGpsDeviceByFixedAssetsId/${assetsId}`,
        method: 'get',
    })
}

//  批量增加gps设备
export function saveGpsInf(data,assetsId) {
    return request({
        url: `/api/manage/gpsDevice/addGpsDeviceList/${assetsId}`,
        method: 'post',
        data
    })
}

//  导出Gps设备表格数据
export function exportGpsExcel(data) {
    return request({
        url: `/api/manage/fixedAssets/exportGpsExcel`,
        method: 'post',
        data,
        responseType: 'blob',//服务器返回的数据类型
        timeout: 500 * 1000,
    })
}