<template>
  <div id="property">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item class="myColor1">首页</el-breadcrumb-item>
      <el-breadcrumb-item class="myColor2">资产管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <!-- 搜索区域视图 -->
      <el-row :gutter="20">
        <el-col :span="4">
          <el-input v-model="queryFrom.carframeNo" placeholder="车架号" class="input-with-select"> </el-input>
        </el-col>
        <el-col :span="3">
          <el-select v-model="queryFrom.cityId" clearable placeholder="所在城市">
            <el-option :key="item.cityId" v-for="item in optionsCity" :label="item.cityName" :value="item.cityId"></el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select v-model="queryFrom.companyId" clearable placeholder="所属公司">
            <el-option :key="item.companyId" v-for="item in optionsCompany" :label="item.companyName" :value="item.companyId"></el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select :disabled="carStatusSelect" v-model="queryFrom.carStatus" clearable placeholder="车辆状态">
            <el-option :key="item.value" v-for="item in optionsStatus" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-date-picker v-model="queryFrom.signingDate" value-format="yyyy-MM-dd" type="date" placeholder="购买时间"> </el-date-picker>
        </el-col>
        <el-col :span="7">
          <el-select v-model="queryFrom.investorId" clearable placeholder="出资人">
            <el-option :key="item.investorId" v-for="item in optionsInvestor" :label="item.investorName" :value="item.investorId"></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4">
          <el-input v-model="queryFrom.carNumber" placeholder="车牌号" class="input-with-select"> </el-input>
        </el-col>
        <el-col :span="8">
          <el-button class="searchButton" type="primary" size="medium" @click="searchList()">查询</el-button>
          <div class="allSettledChecked">
            <el-checkbox @change="allSettledChange" v-model="queryFrom.allSettled" :true-label="1" :false-label="0">已结清资产</el-checkbox>
          </div>
        </el-col>
      </el-row>
    </el-card>
    <el-card>
      <div class="table_header">
        <el-button v-show="isView" type="primary" round size="mini" icon="el-icon-plus" @click="addCarItem()">新增</el-button>
        <el-button type="warning" round size="mini" icon="el-icon-download" @click="DownExcel()">导出</el-button>
        <el-button round size="mini" icon="el-icon-refresh" @click="refreshCar()">刷新</el-button>
        <el-button type="info" v-no-more-click round size="mini" icon="el-icon-map-location" @click="gpsTargetExport()">GPS查询导出</el-button>
      </div>

      <!-- 资产表格数据 -->
      <el-table
        :data="PropertyData"
        style="width: 100%"
        border
        stripe
        :header-cell-style="{ color: '#333', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900, backgroundColor: '#eff3f8' }"
      >
        <el-table-column type="index" label="序" width="50" align="center">
          <template slot-scope="scope">
            <span>{{ (pagenum - 1) * size + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="carframeNo" label="车架号" width="170"> </el-table-column>
        <el-table-column prop="carNumber" label="车牌" width="100"> </el-table-column>
        <el-table-column prop="cityName" label="车牌归属地"> </el-table-column>
        <el-table-column prop="carType" label="车型"> </el-table-column>
        <el-table-column label="首次上牌年份" width="120">
          <template slot-scope="scope">
            {{ scope.row.firstLicenseYear }}
          </template>
        </el-table-column>
        <el-table-column prop="signingDate" label="购买时间" width="100"> </el-table-column>
        <el-table-column align="center" prop="createDate" label="创建时间" width="100"> </el-table-column>
        <el-table-column label="车辆状态" align="center">
          <template slot-scope="scope" v-if="scope.row.carStatus">
            <el-tag :type="tagsStyle[scope.row.carStatus - 1]">
              {{ scope.row.carStatusStr }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" :width="isView ? '280' : '180'" align="center">
          <template slot-scope="scope">
            <el-button v-show="isView" size="mini" type="primary" icon="el-icon-edit" @click="editCarinfo(scope.row.assetsId)">编辑</el-button>
            <el-button class="transfer" v-show="isView" size="mini" type="success" icon="el-icon-sort" @click="transferCar(scope.row)">资产转移</el-button>
            <el-popover placement="top" width="160" trigger="click">
              <el-button v-show="isView" size="mini" type="danger" icon="el-icon-delete" @click="removeCar(scope.row.assetsId)">删除</el-button>
              <el-button size="mini" type="success" icon="el-icon-tickets" @click="readCar(scope.row.id, scope.row.assetsId)">查看</el-button>
              <el-button slot="reference" size="mini" style="#67c23a;marginLeft:10px" icon="el-icon-more"></el-button>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagenum"
        :page-sizes="[1, 5, 10, 20]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 查看车辆详细信息 -->
    <el-dialog :modal-append-to-body="false" :visible.sync="PreviewVisible" width="55%" @close="PerviewhiddlenDialog">
      <el-divider content-position="left">资产信息</el-divider>
      <el-descriptions class="margin-top" :column="3" size="medium" border>
        <el-descriptions-item>
          <template slot="label"> 所在城市 </template>
          {{ readCarInfo.cityName }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 所在公司 </template>
          {{ readCarInfo.companyName }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 车架号 </template>
          {{ readCarInfo.carframeNo }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 车辆类型 </template>
          {{ readCarInfo.carType }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 首次上牌年份 </template>
          {{ readCarInfo.firstLicenseYear }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 车品牌国家 </template>
          {{ readCarInfo.countryName }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 发动机号 </template>
          {{ readCarInfo.engineNo }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 车牌号 </template>
          {{ readCarInfo.carNumber }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 交强险承保公司 </template>
          {{ readCarInfo.compulsoryInsuranceCompanyName }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 交强险到期日 </template>
          {{ readCarInfo.compulsoryInsuranceDueDate }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 商业险承保公司 </template>
          {{ readCarInfo.businessInsuranceCompanyName }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 商业险到期日 </template>
          {{ readCarInfo.businessInsuranceDueDate }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 年检时间 </template>
          {{ readCarInfo.annuaInspectionDate }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 出资人 </template>
          {{ readCarInfo.investorName }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 车辆评估价格 </template>
          {{ readCarInfo.evaluatePrice }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 车辆新旧度 </template>
          {{ readCarInfo.newDegree }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 车辆属性 </template>
          {{ readCarInfo.carAttribute }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 车辆状态 </template>
          {{ readCarInfo.carStatus ? optionsStatus[readCarInfo.carStatus - 1].label : "" }}
        </el-descriptions-item>
        <template v-if="readCarInfo.carStatus == 2">
          <el-descriptions-item>
            <template slot="label"> 处置日期 </template>
            {{ readCarInfo.disposedDate }}
          </el-descriptions-item>
        </template>
        <template v-if="readCarInfo.carStatus == 3">
          <el-descriptions-item>
            <template slot="label"> 拖回日期 </template>
            {{ readCarInfo.dragbackDate }}
          </el-descriptions-item>
          <el-descriptions-item content-class-name="descriptionsNote">
            <template slot="label" :span="2"> 安放点 </template>
            {{ readCarInfo.placementPoint }}
          </el-descriptions-item>
        </template>
        <template v-if="readCarInfo.carStatus == 4">
          <el-descriptions-item>
            <template slot="label"> 出售日期 </template>
            {{ readCarInfo.saleDate }}
          </el-descriptions-item>
        </template>
        <template v-if="readCarInfo.carStatus == 5">
          <el-descriptions-item>
            <template slot="label"> 抵押金额 </template>
            {{ readCarInfo.mortgageAmount }}
          </el-descriptions-item>
        </template>
        <template v-if="readCarInfo.carStatus == 7">
          <el-descriptions-item>
            <template slot="label"> 变更日期 </template>
            {{ readCarInfo.changeDate }}
          </el-descriptions-item>
        </template>
        <template v-if="readCarInfo.carStatus == 8">
          <el-descriptions-item>
            <template slot="label"> 结束合同日期(实际) </template>
            {{ readCarInfo.stopDate }}
          </el-descriptions-item>
        </template>
        <el-descriptions-item>
          <template slot="label"> 实际IRR(%) </template>
          {{ readCarInfo.realIrr }}
        </el-descriptions-item>

        <el-descriptions-item content-class-name="descriptionsNote" :span="3">
          <template slot="label"> 备注 </template>
          {{ readCarInfo.note }}
        </el-descriptions-item>
      </el-descriptions>
      <el-divider content-position="left">采购信息</el-divider>
      <el-descriptions class="margin-top" :column="2" size="medium" border>
        <el-descriptions-item label-class-name="descriptionspurchase">
          <template slot="label"> 供应商名称 </template>
          {{ readCarInfo.supplier }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="descriptionspurchase">
          <template slot="label"> 签订对象 </template>
          {{ readCarInfo.signingName }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="descriptionspurchase">
          <template slot="label"> 采购金额 </template>
          {{ readCarInfo.contractAmount }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="descriptionspurchase">
          <template slot="label"> 采购时间 </template>
          {{ readCarInfo.signingDate }}
        </el-descriptions-item>
      </el-descriptions>
      <template v-if="leaseInfo.length != 0">
        <el-divider content-position="left">租赁信息</el-divider>
        <el-descriptions class="margin-top" :column="3" size="medium" border v-for="item in leaseInfo" :key="item.id">
          <el-descriptions-item label-class-name="ReadcontractNo">
            <template slot="label">
              合同编号
              <el-link icon="el-icon-info" style="fontsize: 10px; margin: 0px" type="primary" @click="SkipLese(item.id)">租赁合同详情</el-link>
            </template>
            {{ item.contractNo }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 客户 </template>
            {{ item.customer }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 签订日期 </template>
            {{ item.signDate }}
          </el-descriptions-item>
        </el-descriptions>
      </template>
    </el-dialog>
  </div>
</template>

<script>
/**
 *

 *
 *
 */
import { propertyData, cityList, companyList, downPropertyList, deleteFixedAssets, FixedAssetsById, leaseData, investorList } from "@/api/index.js";
import { exportGpsExcel } from "@/api/gps.js";
export default {
  name: "property",
  data() {
    return {
      PropertyData: [], // 资产表格数据

      // 搜索组件数据
      queryFrom: {
        carframeNo: "", // 车架号
        cityId: "", // 所在城市
        companyId: "", // 所属公司
        carStatus: "", // 车辆状态
        signingDate: "", // 购买时间
        gpsBindState: "", // gps绑定状态
        allSettled: 0, // 全部结清

        carNumber: "", // 车牌号
        investorId: "", //出资人
      },
      optionsCity: [], // 城市列表
      optionsCompany: [], // 公司列表
      optionsInvestor: [], // 出资人
      optionsStatus: [
        { value: 1, label: "存续" },
        { value: 2, label: "已处置" },
        { value: 3, label: "已拖回" },
        { value: 4, label: "已买断" },
        { value: 5, label: "已抵押" },
        { value: 6, label: "非法过户" },
        { value: 7, label: "待处置" },
        { value: 8, label: "合同结束" },
      ],
      tagsStyle: ["", "success", "warning", "warning", "info", "danger", "success"],

      pagenum: 1, // 页码
      size: 10, // 单页数据数
      total: 10, // 数据总数

      carStatusSelect: false,
      // 查看车辆信息
      readCarInfo: {},
      PreviewVisible: false,
      leaseInfo: [], // 资产相关的合同信息
      optionsGpsStatus: [
        { value: null, label: "全部" },
        { value: 1, label: "已绑定" },
        { value: 0, label: "未绑定" },
      ],
    };
  },
  computed: {
    // 动态遍历需要传的查询参数
    queryFromData() {
      const date = {};
      for (const key in this.queryFrom) {
        // console.log(this.queryFrom[key]);
        if (this.queryFrom[key] !== "") {
          date[key] = this.queryFrom[key];
        }
      }
      return date;
    },

    // 是否只有查看权限
    isView() {
      return JSON.parse(window.sessionStorage.getItem("keyword")) != "view";
    },
  },
  activated() {
    console.log(this.$route.params.addscuss + " " + this.$route.params.editscuss);
    // 新增或编辑成功更新当前页面数据
    if (this.$route.params.addscuss || this.$route.params.editscuss) {
      if (this.$route.params.addscuss) this.pagenum = 1;
      this.size = 10;
      this.getPropertyData();
      console.log("---------编辑添加资产信息----------");
    }

    let assetsId = this.$route.params.assetsId; // 资产id
    let id = this.$route.params.id; // 相关合同id
    console.log(assetsId, id);
    if (assetsId && id && assetsId > 0 && id > 0) {
      // this.getPropertyData();
      this.readCar(assetsId, id);
      assetsId = -1;
      id = -1;
    }
    //判断是否有新增资产(适配资产直接跳转--合同)
    let isaddNewCar = window.sessionStorage.getItem("isaddNewCar");
    console.log(isaddNewCar == "1");
    if (isaddNewCar && isaddNewCar == "1") {
      console.log("刷新数据列表");
      this.refresh();
      window.sessionStorage.setItem("isaddNewCar", "0");
    }
  },
  watch: {
    // 监听时间组件清空值为null
    "queryFrom.signingDate"(newVal) {
      if (newVal == null) {
        this.queryFrom.signingDate = "";
      }
    },
    // 监听城市选项是否为空
    "queryFrom.cityId"(newVal) {
      if (newVal == "") {
        this.optionsCompany = [];
        this.queryFrom.companyId = "";
      } else {
        // 获取公司列表
        this.getcompanyList();
        this.queryFrom.companyId = "";
      }
    },
  },
  created() {
    // 初始化表格数据
    this.getPropertyData();
    this.getcityList();
    // 获取出资人列表
    this.getinvestorList();

    // 获取查看资产详情id
  },

  methods: {
    getPropertyData(refresh) {
      propertyData(this.queryFromData, this.pagenum, this.size).then((res) => {
        console.log(res);
        res.data.dataList.forEach((element) => {
          element.mortgageAmount = Number(element.mortgageAmount).toLocaleString();
        });
        this.PropertyData = res.data.dataList;
        this.total = res.data.total;
        if (refresh == "refresh") this.$message.success("刷新成功");
      });
    },
    // 查询已结清资产（已处置，已买断，合同结束）
    allSettledChange(val) {
      console.log(val);
      switch (val) {
        case 1:
          this.carStatusSelect = true;
          break;
        default:
          this.carStatusSelect = false;
      }
    },
    //刷新数据
    refresh() {
      for (const key in this.queryFrom) {
        this.queryFrom[key] = "";
      }
      this.pagenum = 1;
      this.getPropertyData();
    },
    refreshCar() {
      this.getPropertyData("refresh");
    },
    getcityList() {
      cityList().then((res) => {
        this.optionsCity = res.data.dataList;
        window.sessionStorage.setItem("cityList", JSON.stringify(this.optionsCity));
      });
    },
    getcompanyList() {
      companyList(this.queryFrom.cityId).then((res) => {
        console.log(res);
        this.optionsCompany = res.data.dataList;
      });
    },
    getinvestorList() {
      investorList().then((res) => {
        this.optionsInvestor = res.data.dataList;
      });
    },
    /** ************top数据请求接口*****************/
    // 查询事件按钮
    searchList() {
      // if (Object.keys(this.queryFromData).length === 0) {
      //   this.$message.warning("请输入需要查询的条件!!");
      //   return;
      // }
      this.pagenum = 1;
      this.size = 10;
      this.getPropertyData();
    },

    // 监听pagesize改变的事件
    handleSizeChange(newSize) {
      this.size = newSize;
      this.pagenum = 1;
      this.getPropertyData(); // 发起数据请求
    },
    // 监听页码值改变的事件
    handleCurrentChange(newPage) {
      this.pagenum = newPage;
      this.getPropertyData(); // 发起数据请求
    },
    // 点击新增按钮跳转--/carinfo页面
    addCarItem() {
      // 同步tag标识
      const MenuItem = { name: "新增资产信息", url: "/addcarinfo" };
      this.$store.commit("ChangeTags", MenuItem);
      this.$router.push({ path: "/addcarinfo" });
    },
    // 点击编辑按钮--/carinfo页面
    editCarinfo(assetsId) {
      // 同步tag标识
      const MenuItem = { name: "编辑资产信息", url: "/editcarinfo" };
      this.$store.commit("ChangeTags", MenuItem);
      this.$router.push({ name: "editcarinfo", params: { assetsId } });
    },
    // 导出资产管理列表
    DownExcel() {
      var _this = this;
      downPropertyList(this.queryFromData)
        .then((res) => {
          console.log(res);
          // 1,创建blob对象
          const blob = new Blob([res], {
            type: "application/vnd.ms-excel;charset=utf-8",
          });
          const newTime = _this.$moment().format("YYYYMMDDkkmmss");
          const fileName = "property_" + newTime; // 文件名

          // 2, 创建类文件对象, 导入blob数据源
          const url = window.URL.createObjectURL(blob);

          // 生成a标签存入body,利用herf属性下载
          const dom = document.createElement("a");
          dom.style.display = "none";
          dom.href = url;
          dom.setAttribute("download", fileName);
          document.body.appendChild(dom);
          dom.click();

          this.$message.success("正在下载...");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 点击删除
    removeCar(id) {
      this.$confirm("此操作将删除该固定资产信息, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteFixedAssets(id).then((res) => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.pagenum = 1;
            this.getPropertyData();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 点击查看车辆信息按钮
    readCar(id, assetsId) {
      FixedAssetsById(id).then((res) => {
        // console.log(res.data.contractAmount);
        const contractAmount = res.data.contractAmount;
        const mortgageAmount = res.data.mortgageAmount;
        res.data.contractAmount = contractAmount != null ? Number(contractAmount).toLocaleString() : "";
        res.data.mortgageAmount = mortgageAmount != null ? Number(mortgageAmount).toLocaleString() : "";
        this.readCarInfo = res.data;
        leaseData({ assetsId }, 1, 100).then((res) => {
          console.log(res);
          this.PreviewVisible = true;
          this.leaseInfo = res.data.dataList;
        });
      });
    },

    // 跳转租赁合同详情
    SkipLese(id) {
      this.$router.push({
        name: "lease",
        params: {
          id,
        },
      });
      this.PreviewVisible = false;
    },
    //转移资产按钮
    transferCar(record) {
      //"已处置" "已买断"(已结清)
      if (record.carStatus == "2" || record.carStatus == "4") {
        this.$message.warning("该车辆状态不支持转移!!!");
        return;
      }
      console.log("资产转移", record);
      this.$router.push({ name: "lease", params: { assetsId: record.assetsId, carframeNo: record.carframeNo, replace: true } });
    },
    PerviewhiddlenDialog() {
      this.readCarInfo = {};
      this.leaseInfo = [];
    },
    //gps查询导出
    gpsTargetExport() {
      console.log("查询导出接口");
      if (this.queryFrom.companyId === "") {
        this.$message.warning("请选择所属公司");
        return;
      }
      const queryFrom = this.queryFrom;
      delete queryFrom.gpsBindState;
      delete queryFrom.allSettled;
      var _this = this;
      exportGpsExcel(queryFrom).then((res) => {
        console.log(res);
        // 1,创建blob对象
        const blob = new Blob([res], {
          type: "application/vnd.ms-excel;charset=utf-8",
        });
        const newTime = _this.$moment().format("YYYYMMDDkkmmss");
        const fileName = "gpsList_" + newTime; // 文件名

        // 2, 创建类文件对象, 导入blob数据源
        const url = window.URL.createObjectURL(blob);

        // 生成a标签存入body,利用herf属性下载
        const dom = document.createElement("a");
        dom.style.display = "none";
        dom.href = url;
        dom.setAttribute("download", fileName);
        document.body.appendChild(dom);
        dom.click();

        this.$message.success("正在下载...");
      });
    },
  },
};
</script>

<style lang='less' scoped>
#property {
  padding: 60px 20px 20px 20px;
  /deep/ .el-link--inner {
    margin: 0px;
  }
}
.el-date-editor.el-input {
  width: 100%;
}

.el-button--medium {
  width: 80px;
}
.table_header {
  padding-bottom: 16px;
}
.transfer /deep/ i {
  transform: rotate(90deg);
}
.el-row {
  margin-top: 20px;
}
.allSettledChecked {
  display: inline-block;
  margin-left: 30px;
}
</style>
