<template>
  <div class='carfrom'>
    <el-form ref="rulesForm" :model="form" :rules="rules" label-width="140px" size="small" :validate-on-rule-change='false'>

      <div class="carinfo">
        <span>车辆信息</span>
        <el-divider></el-divider>
        <el-row :gutter="20">
          <el-col :span="6" :offset="4">
            <el-form-item label="所在城市" prop="cityId">
              <el-select v-model="form.cityId" placeholder="请选择所在城市" @change="changeCity(form.cityId)">
                <el-option v-for="item in cityList" :key="item.cityId" :label="item.cityName" :value="item.cityId"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" :offset="4">
            <el-form-item label="所属公司" prop="companyId">
              <el-select v-model="form.companyId" placeholder="请选择所属公司">
                <el-option v-for="item in optionsCompany" :key="item.companyId" :label="item.companyName" :value="item.companyId"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="7" :offset="4">
            <el-form-item label="车架号" prop="carframeNo">
              <el-input v-model.trim="form.carframeNo"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6" :offset="3">
            <el-form-item label="车型" prop="carType">
              <el-input v-model="form.carType"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6" :offset="4">
            <el-form-item label="首次上牌年份" prop="firstLicenseYear">
              <el-date-picker value-format="yyyy" v-model="form.firstLicenseYear" type="year" placeholder="选择年">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6" :offset="4">
            <el-form-item label="车品牌国家" prop="countryId">
              <el-select clearable v-model="form.countryId" placeholder="请选择车品牌国家">
                <el-option v-for="item in optionsCountry" :key="item.countryId" :label="item.countryName" :value="item.countryId"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="7" :offset="4">
            <el-form-item label="发动机号" prop="engineNo">
              <el-input style="width: 275px;" clearable v-model="form.engineNo"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6" :offset="3">
            <el-form-item label="车牌号" prop="carNumber">
              <el-input clearable v-model="form.carNumber"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6" :offset="4">
            <el-form-item label="交强险承保公司" prop="compulsoryInsuranceCompanyName">
              <el-input clearable v-model="form.compulsoryInsuranceCompanyName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6" :offset="4">
            <el-form-item label="交强险到期日">
              <el-date-picker value-format="yyyy-MM-dd" v-model="form.compulsoryInsuranceDueDate" type="date" placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6" :offset="4">
            <el-form-item label="商业险承保公司" prop="businessInsuranceCompanyName">
              <el-input clearable v-model="form.businessInsuranceCompanyName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6" :offset="4">
            <el-form-item label="商业险到期日">
              <el-date-picker value-format="yyyy-MM-dd" v-model="form.businessInsuranceDueDate" type="date" placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6" :offset="4">
            <el-form-item label="年检时间">
              <el-date-picker @change="changeannuaTieShow" value-format="yyyy-MM-dd" v-model="form.annuaInspectionDate" type="date" placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6" :offset="4">
            <el-form-item label="出资人">
              <el-select clearable v-model="form.investorId" placeholder="请选择出资人">
                <el-option v-for="item in optionsInvestor" :key="item.investorId" :label="item.investorName" :value="item.investorId"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6" :offset="4">
            <el-form-item label="车辆评估价格" prop="evaluatePrice">
              <el-input v-model="form.evaluatePrice"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8" :offset="4">
            <el-form-item label="车辆新旧度" prop="newDegree">
              <el-radio v-model="form.newDegree" label="新车">新车</el-radio>
              <el-radio v-model="form.newDegree" label="二手车">二手车</el-radio>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6" :offset="4">
            <el-form-item label="车辆属性" prop="carAttribute">
              <el-select v-model="form.carAttribute" placeholder="请选择">
                <el-option  label="新能源车" value="新能源车"></el-option>
                <el-option  label="燃油车" value="燃油车"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" :offset="4">
            <el-form-item label="实际IRR(%)" prop="realIrr">
              <el-input v-model="form.realIrr" maxlength="10"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6" :offset="4">
            <el-form-item label="车辆状态" prop="carStatus">
              <el-select clearable v-model="form.carStatus" placeholder="请选择车辆状态" @change="changgeCarState(form.carStatus)">
                <el-option v-for="item in optionsStatus" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <div class="state2" v-if="form.carStatus==2">
              <el-form-item label="处置日期">
                <el-date-picker value-format="yyyy-MM-dd" v-model="form.disposedDate" type="date" placeholder="选择日期">
                </el-date-picker>
              </el-form-item>
            </div>
            <div class="state3" v-if="form.carStatus==3">
              <el-form-item label="拖回日期">
                <el-date-picker value-format="yyyy-MM-dd" v-model="form.dragbackDate" type="date" placeholder="选择日期">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="安放点" prop="placementPoint">
                <el-input v-model="form.placementPoint"></el-input>
              </el-form-item>
            </div>
            <div class="state4" v-if="form.carStatus==4">
              <el-form-item label="出售日期">
                <el-date-picker value-format="yyyy-MM-dd" v-model="form.saleDate" type="date" placeholder="选择日期">
                </el-date-picker>
              </el-form-item>
            </div>
            <div class="state5" v-show="form.carStatus==5">
              <el-form-item label="抵押金额" prop="mortgageAmount" ref="carstateItem">
                <el-input clearable v-model="form.mortgageAmount"></el-input>
              </el-form-item>
            </div>
            <div class="state7" v-if="form.carStatus==7">
              <el-form-item label="变更日期">
                <el-date-picker value-format="yyyy-MM-dd" v-model="form.changeDate" type="date" placeholder="选择日期">
                </el-date-picker>
              </el-form-item>
            </div>
            <div class="state8" v-if="form.carStatus==8">
              <el-form-item label="结束合同日期(实际)">
                <el-date-picker value-format="yyyy-MM-dd" v-model="form.stopDate" type="date" placeholder="选择日期">
                </el-date-picker>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="6" :offset="4">
            <el-form-item label="备注" prop="note">
              <el-input type="textarea" v-model="form.note"></el-input>
            </el-form-item>
          </el-col>          
        </el-row>
      </div>
      <div v-if="$route.path=='/editcarinfo'&&assetsId">
        <div class="gpsTitle">
          <span>车辆GPS设备情况</span>
          <div style="margin-right:100px">
            <el-button plain type="primary" size="mini" @click="editgpsDisalog">编辑GPS设备</el-button>
          </div>
        </div>
        <el-divider></el-divider>
        <div v-if="gpsListOld.length>0" class="tableBox">
          <el-table :data="gpsListOld" width='80' border>
            <el-table-column property="manufacturer" label="GPS设备厂商"></el-table-column>
            <el-table-column property="imei" label="设备IMEI" width="300"></el-table-column>
            <el-table-column property="type" label="设备类型">
              <template slot-scope="scope">
                {{scope.row.type===null?'--':scope.row.type===1?'有线':'无线'}}
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div v-else>
          <el-empty description="暂未绑定GPS设备"></el-empty>
        </div>
      </div>
      <div>
        <span>采购信息</span>
        <el-divider></el-divider>
        <el-row :gutter="20">
          <el-col :span="7" :offset="4">
            <el-form-item label="供应商名称" prop="supplier">
              <el-input v-model="form.supplier"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5" :offset="3">
            <el-form-item label="签订对象" prop="signingName">
              <el-input v-model="form.signingName"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="7" :offset="4">
            <el-form-item label="采购金额" prop="contractAmount">
              <el-input v-model="form.contractAmount"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5" :offset="3">
            <el-form-item label="采购时间" prop="signingDate">
              <el-date-picker value-format="yyyy-MM-dd" v-model="form.signingDate" type="date" placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </div>

      <div id="carinfoBottom">
        <span>租赁信息</span>
        <el-divider></el-divider>
        <div v-if="leaseInfo.length != 0">
          <el-descriptions v-for="item in leaseInfo" :key="item.id" direction="vertical">
            <el-descriptions-item label="合同编号">{{ item.contractNo }}</el-descriptions-item>
            <el-descriptions-item label="签订对象">{{ item.customer }}</el-descriptions-item>
            <el-descriptions-item label="签订日期">{{ item.signDate }}</el-descriptions-item>
          </el-descriptions>
        </div>
        <div v-else>
          <el-empty description="暂无租赁信息"></el-empty>
        </div>

      </div>

      <div class="back">
        <el-row :gutter="20">
          <el-col :span="12" :offset="6" align="center">
            <el-button type="primary" size="" @click="SaveDate()">保存</el-button>
            <el-button type="danger" @click="cancel()">取消</el-button>
            <div class="saveSkip" v-if="isSkip">
              <el-button plain size="" @click="SaveSkip()">保存并新增合同</el-button>
            </div>
          </el-col>
        </el-row>
      </div>

    </el-form>
    <div id="gpsList">
      <el-dialog :close-on-click-modal="false" :visible.sync="dialogTableGps" width="60%" title="车辆GPS设备列表">
        <el-form :inline="true" :model="gpsValidateForm" size="mini" ref="gpsValidateForm">
          <el-row :gutter="20" v-for="(item, index) in gpsValidateForm.domains" :key="item.id">
            <el-col>
              <el-form-item>
                <span>设备厂商</span>
                <el-select @change="((item)=>{selectManufacturer(item,index)})" style="width:120px;margin-right:30px" v-model="item.manufacturer" placeholder="请选择">
                  <el-option v-for="item in gpsOptions" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item :prop="'domains[' + index + '].imei'" :rules="[
                                {message: '设备IMEI范围在5到20个数值字符',pattern: /^\d{5,20}$/,trigger: 'blur'}
                              ]">
                <span>设备IMEI</span>
                <el-input style="width:200px" v-model="item.imei"></el-input>
              </el-form-item>
              <el-form-item v-if="item.manufacturer=='丰鸟好车'? true : false">
                <span>设备类型</span>
                <el-radio v-model="item.type" :label=1>有线</el-radio>
                <el-radio v-model="item.type" :label=0>无线</el-radio>
              </el-form-item>
              <el-form-item>
                <el-button style="margin-left:10px" icon="el-icon-delete" type="danger" circle @click.prevent="removeGps(index)"></el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-divider></el-divider>
          <el-button plain type="primary" size="mini" @click="addgpsDomain">添加设备</el-button>
          <el-button size="mini" @click="dialogTableGps = false">取 消</el-button>
          <el-button size="mini" type="primary" @click="submitForm('gpsValidateForm')">确认</el-button>
        </div>
      </el-dialog>
    </div>

  </div>
</template>

<script>
import { companyList, countryList, investorList } from '@/api/index.js'
import { saveGpsInf } from '@/api/gps.js'

export default {
  name: '',
  props: {
    editForm: {
      type: Object,
      default: () => {}
    },
    leaseInfo: {
      type: Array,
      default: () => []
    },
    assetsId: {
      type: Number,
      default: () => {}
    },
    gpsListOld: {
      type: Array,
      default: () => {}
    }
  },
  data () {
    return {
      dialogTableGps: false, // gps列表弹窗
      // gps厂商列表
      gpsOptions: [
        {
          value: '途强在线',
          label: '途强在线'
        },
        {
          value: '丰鸟好车',
          label: '丰鸟好车'
        }
      ],
      // gps设备列表
      gpsValidateForm: {
        domains: []
      },
      cityList: [],
      optionsCompany: [],
      optionsCountry: [],
      optionsInvestor: [],
      optionsStatus: [
        { value: 1, label: '存续' },
        { value: 2, label: '已处置' },
        { value: 3, label: '已拖回' },
        { value: 4, label: '已买断' },
        { value: 5, label: '已抵押' },
        { value: 6, label: '非法过户' },
        { value: 7, label: '待处置' },
        { value: 8, label: '合同结束' }
      ],

      // 新增表单
      form: {
        cityId: '', // 城市id
        companyId: '', // 公司id
        carframeNo: '', // 车架号
        carType: '', // 车型
        firstLicenseYear: '', // 首次上牌年份
        countryId: '', // 车品牌--国家
        engineNo: '', // 发动机号
        carNumber: '', // 车牌号
        compulsoryInsuranceCompanyName: '', // 交强险承保公司
        compulsoryInsuranceDueDate: '', // 交强险到期日
        businessInsuranceCompanyName: '', // 商业险承保公司
        businessInsuranceDueDate: '', // 商业险到期日
        annuaInspectionDate: '', // 年检时间
        investorId: '', // 出资人
        carStatus: '', // 车辆状态
        disposedDate: '', // 处置日期--状态已处置
        dragbackDate: '', // 拖回日期--状态已拖回
        placementPoint: '', // 安放点--状态已拖回

        saleDate: '', // 出售日期--状态已买断
        changeDate: '', // 变更日期--状态待处置

        mortgageAmount: '', // 抵押金额--已抵押
        realIrr: '', // 实际irr
        note: '', // 备注
        /** *采购信息 */
        supplier: '', // 供应商名称
        signingName: '', // 签订对象
        contractAmount: '', // 采购金额
        signingDate: '' ,// 签订日期

        evaluatePrice: '', // 评估价
        newDegree: '新车', // 车辆新旧度

        carAttribute: '', //车辆属性

        stopDate: '', //实际合同结束日期
      },

      /** *租赁信息 */
      contractNo: '', // 合同编号
      customer: '', // 客户姓名
      signDate: '', // 采购时间

      /** 表单验证规则 */
      rules: {
        cityId: [
          {
            required: true,
            message: '请选择所在城市',
            trigger: ['blur', 'change']
          }
        ],
        companyId: [
          {
            required: true,
            message: '请选择所在公司',
            trigger: ['blur', 'change']
          }
        ],
        carframeNo: [
          { required: true, message: '请输入车架号', trigger: 'blur' },
          {
            message: '车架号格式错误(标准17位)',
            pattern: /^[a-zA-Z0-9]{17}$/,
            trigger: 'blur'
          }
        ],
        carType: [
          { required: true, message: '请输入车辆类型', trigger: 'blur' },
          {
            min: 1,
            max: 30,
            message: '车型信息长度在1到30个字符',
            trigger: 'blur'
          }
        ],
        firstLicenseYear: [{ required: true, message: '请选择首次上牌年份', trigger: 'blur' }],
        countryId: [
          {
            required: true,
            message: '请选择车品牌国家',
            trigger: ['blur', 'change']
          }
        ],
        engineNo: [
          {
            min: 0,
            max: 25,
            message: '发动机号长度在0到25个字符',
            trigger: 'blur'
          }
        ],
        carNumber: [
          {
            message: '车牌号格式错误',
            // pattern: /^[\u4e00-\u9fa5]{1}[A-Z]{1}[A-Z0-9]{5}$/,
            // (含新能源电车)
            pattern:
              // /^(([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z](([0-9]{5}[DF])|([DF]([A-HJ-NP-Z0-9])[0-9]{4})))|([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z][A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳使领]))$/,
              /^[\u4e00-\u9fa5]{1}[A-Z]{1}[A-Z_0-9]{5,6}$/,
            trigger: 'blur'
          }
        ],
        placementPoint: [
          {
            min: 0,
            max: 50,
            message: '安放点信息长度在0到50个字符',
            trigger: 'blur'
          }
        ],
        note: [
          {
            min: 0,
            max: 500,
            message: '备注信息长度在0到500个字符',
            trigger: 'blur'
          }
        ],
        newDegree: [
          { required: true, message: '请选择车辆新旧度', trigger: 'blur' }
        ],
        evaluatePrice: [
          {
            message: '评估价格不能超过10位正整数',
            pattern: /^\d{1,10}$/,
            trigger: 'blur'
          }
        ],
        mortgageAmount: [
          {
            message: '抵押金额不能超过10位正整数',
            pattern: /^\d{1,10}$/,
            trigger: 'blur'
          }
        ],
        contractAmount: [
          { required: true, message: "请输入采购金额", trigger: "blur" },
          {
            message: '采购金额不能超过10位正整数',
            pattern: /^\d{1,10}$/,
            trigger: 'blur'
          }
        ],
        carStatus: [{ required: true, message: '请选择车辆状态', trigger: 'blur' }],
        signingName: [
          {
            min: 1,
            max: 20,
            message: '长度不能超过20个字符',
            trigger: 'blur'
          }
        ],
        supplier: [
          {
            min: 1,
            max: 50,
            message: '长度不能超过50个字符',
            trigger: 'blur'
          }
        ],
        realIrr: [
          {
            message: '请输入数字(最多两位小数)',
            pattern: /(^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:\.\d{1,2})?$)/,
            trigger: 'blur'
          }
        ],
        compulsoryInsuranceCompanyName: [
          {
            min: 1,
            max: 100,
            message: '长度不能超过100个字符',
            trigger: 'blur'
          }
        ],
        businessInsuranceCompanyName: [
          {
            min: 1,
            max: 100,
            message: '长度不能超过100个字符',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  watch: {
    // 监听时间组件清空值为null
    'form.firstLicenseYear' (newVal) {
      if (newVal == null) {
        this.form.firstLicenseYear = ''
      }
    },
    'form.compulsoryInsuranceDueDate' (newVal) {
      if (newVal == null) {
        this.form.compulsoryInsuranceDueDate = ''
      }
    },
    'form.businessInsuranceDueDate' (newVal) {
      if (newVal == null) {
        this.form.businessInsuranceDueDate = ''
      }
    },
    'form.annuaInspectionDate' (newVal) {
      if (newVal == null) {
        this.form.annuaInspectionDate = ''
      }
    },
    'form.saleDate' (newVal) {
      if (newVal == null) {
        this.form.saleDate = ''
      }
    },
    'form.signingDate' (newVal) {
      if (newVal == null) {
        this.form.signingDate = ''
      }
    },

    // 监听车辆状态
    'form.carStatus' (newVal, oldVal) {
      console.log(oldVal)
      if (oldVal == 3) {
        this.form.dragbackDate = ''
        this.form.placementPoint = ''
      } else if (oldVal == 4) {
        this.form.saleDate = ''
      } else if (oldVal == 5) {
        this.form.mortgageAmount = ''
      } else if (oldVal == 2) {
        this.form.disposedDate = ''
      } else if (oldVal == 7) {
        this.form.changeDate = ''
      } else if (oldVal == 8) {
        this.form.stopDate = ''
      }
    },
    // 监听城市选项是否为空
    'form.cityId' (newVal, oldVal) {
      console.log(newVal, oldVal)
    },

    editForm (o, n) {
      console.log('hixian' + o, n)
      // 回显编辑信息---
      if (this.$route.path == '/editcarinfo') {
        this.form = o
        this.getcompanyList()
      }
    }
  },
  created () {},
  computed: {
    isSkip () {
      if (this.$route.name == 'addcarinfo') {
        return true
      } else {
        return false
      }
    }
  },
  activated () {
    // 取出表单需要的城市列表
    var result = JSON.parse(sessionStorage.getItem('cityList'))
    console.log(result)
    this.cityList = result
    // 获取汽车品国家列表
    this.getCountryList()
    // 获取出资人列表
    this.getinvestorList()
    // 获取公司列表
  },
  methods: {
    getcompanyList () {
      companyList(this.form.cityId).then((res) => {
        console.log(res)
        this.optionsCompany = res.data.dataList
      })
    },
    getCountryList () {
      countryList().then((res) => {
        console.log(res)
        this.optionsCountry = res.data.dataList
      })
    },
    getinvestorList () {
      investorList().then((res) => {
        this.optionsInvestor = res.data.dataList
      })
    },

    // 保存数据
    SaveDate () {
      this.$refs.rulesForm.validate((valid) => {
        if (valid) {
          this.$emit('addCarInfo', this.form, '')
          this.$emit('editCarInfo', this.form)
        } else {
          this.$message.warning('请检查各表单验证提示信息')
          return false
        }
      })
    },

    // 保存数据并跳转合同菜单
    SaveSkip () {
      this.$refs.rulesForm.validate((valid) => {
        if (valid) {
          this.$emit('addCarInfo', this.form, 'saveSkip')
        } else {
          this.$message.warning('请检查各表单验证提示信息')
          return false
        }
      })
    },

    // 重置表单数据
    resestFormDate () {
      this.$refs.rulesForm.resetFields() /// /添加表单验证--重置数据
    },

    changeCity (cityid) {
      console.log(cityid)
      this.form.companyId = ''
      this.getcompanyList()
    },
    changgeCarState (state) {
      if (state != 5) {
        this.$refs.carstateItem.clearValidate() // 取消表单验证
      }
    },

    // cancel取消
    cancel () {
      this.$emit('addCarCancel')
      this.$emit('editCarCancel')
      this.$refs.rulesForm.resetFields() /// /添加表单验证--重置数据
    },

    // 解决选择日期组件不回显的问题
    changeannuaTieShow (value) {
      console.log('显示年检时间')
    },

    // 切换设备厂商
    selectManufacturer (item, index) {
      console.log(index)
      if (item == '途强在线') {
        this.gpsValidateForm.domains[index].type = '' // 类型默认值
      } else {
        this.gpsValidateForm.domains[index].type = 1 // 默认值
      }
    },

    // 点击编辑gps按钮
    editgpsDisalog () {
      const newGpsList = [...this.gpsListOld].map((obj) => Object.assign({}, obj))
      const startItem = [{
        id: 0,
        manufacturer: '',
        imei: '',
        type: ''
      }]
      this.gpsValidateForm.domains = newGpsList.length > 0 ? newGpsList : startItem
      this.dialogTableGps = true
    },
    // 移除gps设备
    removeGps (index) {
      console.log(index)
      this.gpsValidateForm.domains.splice(index, 1)
    },

    // 新增gps设备
    addgpsDomain () {
      this.gpsValidateForm.domains.push({
        id: Date.now(),
        manufacturer: '',
        imei: '',
        type: ''
      })
    },

    // 校验gps表单
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        console.log(valid)
        if (valid) {
          let gpsList = []
          // 防止重复提交,检验IMEI
          const imeis = new Set()
          for (const obj of this.gpsValidateForm.domains) {
            if (obj.imei != '' && imeis.has(obj.imei)) {
              console.log(obj.imei)
              this.$message.warning('设备IMEI: ' + obj.imei + '重复,请检查列表信息')
              return
            }
            imeis.add(obj.imei)
          }
          gpsList = this.gpsValidateForm.domains.map((item) => {
            const { id, manufacturer, ...rest } = item
            return { manufacturer, ...rest }
          })
          // 剔除空的输入表单数据
          const newGpsList = gpsList.filter((obj) => {
            console.log(obj)
            if (obj.imei === '' && obj.manufacturer === '') {
              return false
            } else {
              return true
            }
          })
          console.log(gpsList, newGpsList, this.assetsId)
          saveGpsInf(newGpsList, this.assetsId).then((res) => {
            console.log(res)
            this.$message.success('GPS设备保存成功')
            this.$emit('updateGpsList', this.assetsId)
            this.dialogTableGps = false
          })
        }
      })
    }
  }
}
</script>

<style lang='less' scoped>
.el-divider {
  margin: 4px 0 20px 0;
}
.carinfo {
  margin-bottom: 20px;
}

.back {
  margin-top: 40px;
  .el-button {
    margin: 0 20px;
    padding: 8px 20px;
  }
}

.el-descriptions {
  width: 90%;
  padding-left: 240px;
}

.saveSkip {
  display: inline-block;
}

#gpsList {
  span {
    margin: 0 10px;
  }
  .el-radio {
    margin-right: 10px;
  }
  button {
    // margin-right: 45px;
  }
}
.gpsTitle {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.tableBox {
  width: 60%;
  margin: 0 auto;
}
</style>
