<template>
  <div class='editcarinfo'>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item class="myColor1">首页</el-breadcrumb-item>
      <el-breadcrumb-item class="myColor2">资产管理</el-breadcrumb-item>
      <el-breadcrumb-item class="myColor2">编辑资产信息</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">

      <div class="historyVersion">
        <el-link type="primary" @click="enterVerson()">当前版本V{{editForm.versionNo}}</el-link>
      </div>
      <carFrom ref="child" @updateGpsList="queryGpsList" :gpsListOld="gpsListOld" :assetsId="assetsId" @editCarInfo="editCar" :editForm="editForm" :leaseInfo="leaseInfo" @editCarCancel="cancel"></carFrom>
    </el-card>
    <div class="VersionBox">
      <el-dialog :visible.sync="dialogTableVisible">
        <div slot="title">
          <span>历史版本</span>
          <span class="minititle">(仅保留最近20次历史版本)</span>
        </div>
        <el-table :data="gridData">
          <el-table-column property="versionNo" label="版本号 (↑最新版)"></el-table-column>
          <el-table-column property="updateUserName" label="姓名"></el-table-column>
          <el-table-column property="updateDate" label="时间" width="180"></el-table-column>
          <el-table-column property="address" label="操作" width="160">
            <template class="link" slot-scope="scope">
              <el-link :underline="false" type="primary" @click="showInner(scope.row.id,scope.row.versionNo)">预览</el-link>
              <el-link :underline="false" type="primary" @click="setVersionToNew(scope.row.id)">设为最新版</el-link>
            </template>
          </el-table-column>
        </el-table>

        <!-- 嵌套弹窗表格 -->
        <el-dialog class="carinfoDialog" width="60%" :title="'信息预览V'+newVersion" :visible.sync="innerVisible" :append-to-body="true" @close='hiddlenMinidislog'>
          <el-descriptions title="车辆信息">
            <el-descriptions-item label="所在城市">{{showInnerData.cityName}}</el-descriptions-item>
            <el-descriptions-item label="所属公司">{{showInnerData.companyName}}</el-descriptions-item>
            <el-descriptions-item label="车架号">{{showInnerData.carframeNo}}</el-descriptions-item>
            <el-descriptions-item label="车型">{{showInnerData.carType}}</el-descriptions-item>
            <el-descriptions-item label="首次上牌年份">{{showInnerData.firstLicenseYear}}</el-descriptions-item>
            <el-descriptions-item label="车品牌国家">{{showInnerData.countryName}}</el-descriptions-item>
            <el-descriptions-item label="发动机号">{{showInnerData.engineNo}}</el-descriptions-item>
            <el-descriptions-item label="车牌号">{{showInnerData.carNumber}}</el-descriptions-item>
            <el-descriptions-item label="交强险承保公司">{{showInnerData.compulsoryInsuranceCompanyName}}</el-descriptions-item>
            <el-descriptions-item label="交强险到期日">{{showInnerData.compulsoryInsuranceDueDate}}</el-descriptions-item>
            <el-descriptions-item label="商业险承保公司">{{showInnerData.businessInsuranceCompanyName}}</el-descriptions-item>
            <el-descriptions-item label="商业险到期日">{{showInnerData.businessInsuranceDueDate}}</el-descriptions-item>
            <el-descriptions-item label="	年检时间">{{showInnerData.annuaInspectionDate}}</el-descriptions-item>
            <el-descriptions-item label="	出资人">{{showInnerData.investorName}}</el-descriptions-item>
            <el-descriptions-item label="车辆评估价格">{{showInnerData.evaluatePrice}}</el-descriptions-item>
            <el-descriptions-item label="车辆新旧度">{{showInnerData.newDegree}}</el-descriptions-item>
            <el-descriptions-item label="车辆属性">{{showInnerData.carAttribute}}</el-descriptions-item>
            <el-descriptions-item label="	车辆状态">{{showInnerData.carStatus==null?'':optionsStatus[showInnerData.carStatus-1].label}}</el-descriptions-item>
            <template v-if="showInnerData.carStatus==2">
              <el-descriptions-item label='处置日期'>
                {{showInnerData.disposedDate}}
              </el-descriptions-item>
            </template>
            <template v-if="showInnerData.carStatus==3">
              <el-descriptions-item label='拖回日期'>
                {{showInnerData.dragbackDate}}
              </el-descriptions-item>
              <el-descriptions-item label='安放点'>
                {{showInnerData.placementPoint}}
              </el-descriptions-item>
            </template>
            <template v-if="showInnerData.carStatus==4">
              <el-descriptions-item label='出售日期'>
                {{showInnerData.saleDate}}
              </el-descriptions-item>
            </template>
            <template v-if="showInnerData.carStatus==5">
              <el-descriptions-item label='抵押金额'>
                {{showInnerData.mortgageAmount}}
              </el-descriptions-item>
            </template>
            <template v-if="showInnerData.carStatus==7">
              <el-descriptions-item label='变更日期'>
                {{showInnerData.changeDate}}
              </el-descriptions-item>
            </template>
            <template v-if="showInnerData.carStatus==8">
              <el-descriptions-item label='结束合同日期(实际)'>
                {{showInnerData.stopDate}}
              </el-descriptions-item>
            </template>
            <el-descriptions-item label="	实际IRR(%)">{{showInnerData.realIrr}}</el-descriptions-item>
            <el-descriptions-item label="	备注">{{showInnerData.note}}</el-descriptions-item>
          </el-descriptions>
          <el-descriptions title="采购信息">
            <el-descriptions-item label="供应商名称">{{showInnerData.supplier}}</el-descriptions-item>
            <el-descriptions-item label="签订对象">{{showInnerData.signingName}}</el-descriptions-item>
            <el-descriptions-item label="采购金额">{{showInnerData.contractAmount}}</el-descriptions-item>
            <el-descriptions-item label="采购时间">{{showInnerData.signingDate}}</el-descriptions-item>
          </el-descriptions>
          <div id="carinfoDialogbottom">
            <template v-if="preleaseInfo.length!=0">
              <div class="titlePre">租赁信息</div>
              <el-descriptions v-for="item in preleaseInfo" :key="item.id" direction="vertical">
                <el-descriptions-item label="合同编号">{{item.contractNo}}</el-descriptions-item>
                <el-descriptions-item label="签订对象">{{item.customer}}</el-descriptions-item>
                <el-descriptions-item label="签订日期">{{item.signDate}}</el-descriptions-item>
              </el-descriptions>
            </template>
          </div>
        </el-dialog>
      </el-dialog>
    </div>
  </div>
</template>
    
<script>
import carFrom from "./commons/carFrom.vue";
import {
  FixedAssetsById,
  updateFixedAssets,
  fixedAssetsHistory,
  fixedAssetsHistoryInfo,
  setToNew,
  leaseData,
} from "@/api/index.js";
import { mapMutations } from "vuex";
import {getGpsList} from "@/api/gps.js"

export default {
  name: "editcarinfo",
  components: { carFrom },
  data() {
    return {
      assetsId: -1, //资产车辆id

      editForm: {}, //预编辑的参数

      dialogTableVisible: false,
      innerVisible: false,
      gridData: [],
      showInnerData: {},
      optionsStatus: [
        { value: 1, label: "存续" },
        { value: 2, label: "已处置" },
        { value: 3, label: "已拖回" },
        { value: 4, label: "已买断" },
        { value: 5, label: "已抵押" },
        { value: 6, label: "非法过户" },
        { value: 7, label: "待处置" },
        { value: 8, label: "合同结束" },
      ],
      newVersion: "", //当前预览版本号
      leaseInfo: [], //资产===租赁信息
      preleaseInfo: [], //预览版本==租赁信息
      gpsListOld: [], // 资产已绑定gps设备信息
      exceptionalEdit: false, //是否是异常资产编辑
    };
  },
  created() {},
  mounted() {
    //监听页面刷新,直接跳转到资产管理
    window.addEventListener("load", () => {
      //写入你想要执行的代码
      this.$store.commit("reset");
      this.$router.push({ path: "/property" });
    });
  },
  activated() {
    console.log(this.assetsId + "进入", this.$route.params);
    if (this.$route.params.assetsId) {
      this.assetsId = this.$route.params.assetsId;

      this.getIdCarinfo(this.assetsId);
      this.getleaselist(this.assetsId);
      this.queryGpsList(this.assetsId);
    }

    if (this.$route.params.exceptionalEdit){
      this.exceptionalEdit = this.$route.params.exceptionalEdit
    } else {
      this.exceptionalEdit = false
    }
  },
  methods: {
    ...mapMutations({
      reset: (state) => state.tab.reset,
    }),
    //提交修改
    editCar(formDate) {
      console.log(formDate);
      updateFixedAssets(formDate, this.assetsId)
        .then((res) => {
          console.log(res);
          this.$refs.child.resestFormDate(); //重置表单数据
          this.$notify.closeAll(); //隐藏错误提示信息

          this.$message.success("编辑成功");

          console.log();

          let item = {
            url: "/editcarinfo",
          };
          this.$store.commit("closeTab", item);
          if (this.exceptionalEdit) {
            this.$router.push({ name: "exceptional" });
          } else {
            this.$router.push({ name: "property", params: { editscuss: true } });
          }
        })
        .catch((err) => {
          console.log(err);
          let arr = [];
          if (err.code == 10001) {
            for (const key in err.data) {
              arr.push("● " + err.data[key]);
            }
            let str = arr.join(" <br/> ");
            this.$notify.error({
              dangerouslyUseHTMLString: true,
              title: "错误",
              message: str,
              position: "top-right",
              duration: 5000,
            });
          }
        });
    },
    //获取当前资产的详细信息
    getIdCarinfo(id) {
      console.log(id);
      FixedAssetsById(id).then((res) => {
        console.log(res);
        res.data.firstLicenseYear = res.data.firstLicenseYear + "";
        this.editForm = res.data;
      });
    },
    //获取当前资产对应的租赁合同信息
    getleaselist(assetsId) {
      leaseData({ assetsId }, 1, 100).then((res) => {
        console.log(res);
        this.leaseInfo = res.data.dataList;
      });
    },

    //取消
    cancel() {
      console.log("取消");
      // this.$router.push({ path: "/property" });
      if (this.exceptionalEdit) {
          this.$router.push({ name: "exceptional" });
          } else {
          this.$router.push({ path: "/property" });
          }
      let item = {
        url: "/editcarinfo",
      };
      this.$store.commit("closeTab", item);
    },
    //点击历史版本按钮
    enterVerson() {
      //获取历史版本列表
      fixedAssetsHistory(this.assetsId, 1, 20).then((res) => {
        console.log(res);
        this.gridData = res.data.dataList;
        this.dialogTableVisible = true;
      });
    },

    //showInner点击预览按钮
    showInner(id, version) {
      console.log(id, version);
      this.newVersion = version;
      fixedAssetsHistoryInfo(id).then((res) => {
        console.log(res);
        const contractAmount = res.data.contractAmount;
        const mortgageAmount = res.data.mortgageAmount;
        res.data.contractAmount =
          contractAmount != null ? Number(contractAmount).toLocaleString() : "";
        res.data.mortgageAmount =
          mortgageAmount != null ? Number(mortgageAmount).toLocaleString() : "";
        this.showInnerData = res.data;
        this.innerVisible = true;
      });
      let assetsId = this.assetsId;
      leaseData({ assetsId }, 1, 100).then((res) => {
        console.log(res);
        this.preleaseInfo = res.data.dataList;
      });
    },

    //设置当前版本
    setVersionToNew(id) {
      setToNew(id).then((res) => {
        console.log(id);
        this.dialogTableVisible = false;
        this.$message.success("已设置当前版本为最新版");
        let item = {
          url: "/editcarinfo",
        };
        this.$store.commit("closeTab", item);
        // this.$router.push({ name: "property", params: { addscuss: true } });
        if (this.exceptionalEdit) {
            this.$router.push({ name: "exceptional" });
          } else {
            this.$router.push({ name: "property", params: { editscuss: true } });
          }
      });
    },

    //隐藏预览信息弹窗
    hiddlenMinidislog() {
      this.newVersion = "";
      this.preleaseInfo = [];
    },
    // 查询gps设备列表
    queryGpsList(id){
      console.log(id);
      getGpsList(id).then((res) => {
        console.log(res);
        this.gpsListOld = res.data
      });
    }
  },
};
</script>
    
<style lang='less' scoped>
.editcarinfo {
  padding: 20px;
}
.historyVersion {
  text-align: right;
  padding: 5px 50px 10px 0px;
  .el-link {
    font-weight: 900 !important;
  }
}
.cell {
  .el-link {
    margin-right: 20px;
  }
}
.minititle {
  font-size: 12px;
  color: gray;
}
.titlePre {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 700;
}
</style>