<template>
  <div class='carinfo'>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item class="myColor1">首页</el-breadcrumb-item>
      <el-breadcrumb-item class="myColor2">资产管理</el-breadcrumb-item>
      <el-breadcrumb-item class="myColor2">新增资产信息</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <!-- 编辑表单 -->
      <carFrom @addCarInfo="addCar" @addCarCancel="cancel" ref="child"></carFrom>
    </el-card>
  </div>
</template>
    
<script>
import carFrom from "./commons/carFrom.vue";
import { addFixedAssets } from "@/api/index.js";

export default {
  components: { carFrom },
  name: "addcarinfo",
  data() {
    return {};
  },
  created() {},
  mounted() {
    //监听页面刷新,直接跳转到资产管理
    window.addEventListener("load", () => {
      //写入你想要执行的代码
      this.$store.commit("reset");
      this.$router.push({ path: "/property" });
    });
  },
  methods: {
    addCar(formDate,saveSkip) {
      console.log(formDate,saveSkip);
      addFixedAssets(formDate)
        .then((res) => {
          console.log(res);
          this.$message.success("新增成功");
          this.$refs.child.resestFormDate(); //重置表单数据
          this.$notify.closeAll(); //隐藏错误提示信息

          let item = {
            url: "/addcarinfo",
          };
          this.$store.commit("closeTab", item);
          if (saveSkip=="") {
            this.$router.push({ name: "property", params: { addscuss: true } });
          } else if (saveSkip == "saveSkip") {
            // 资产新增标记isaddNewCar 判断是否刷新资产列表
            window.sessionStorage.setItem('isaddNewCar', "1")
            this.$router.push({ name: "lease", params: { assetsId: res.data.assetsId,carframeNo:res.data.carframeNo} });            
          }
        })
        .catch((err) => {
          console.log(err);
          let arr = [];
          if (err.code == 10001) {
            for (const key in err.data) {
              arr.push("● " + err.data[key]);
            }
            let str = arr.join(" <br/> ");
            this.$notify.error({
              dangerouslyUseHTMLString: true,
              title: "错误",
              message: str,
              position: "top-right",
              duration: 5000,
            });
          }
        });
    },
    cancel() {
      this.$router.push({ path: "/property" });
      let item = {
        url: "/addcarinfo",
      };
      this.$store.commit("closeTab", item);
    },
  },
};
</script>
    
<style lang='less' scoped>
.carinfo {
  padding: 20px;
}
</style>
